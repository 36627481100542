<template>
  <div class="score-query">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon
            class="btn"
            v-show="!pagesStatus.messageInfoStatus"
            @click="$router.push('/protal')"
            ><ArrowLeftBold
          /></el-icon>
          <el-icon
            class="btn"
            v-show="pagesStatus.messageInfoStatus"
            @click="back"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        消息通知</el-col
      >
    </el-row>

    <el-row class="message-content" v-if="pagesStatus.messageInfoStatus">
      <el-col><div v-html="state.infoContent"></div></el-col>
    </el-row>
    <el-row v-else-if="state.errorMessage">
      <el-col
        ><div class="errorMessage">{{ state.errorMessage }}</div></el-col
      >
    </el-row>
    <el-row v-loading="pagesStatus.loading" class="message-content" v-else>
      <el-col>
        <div style="height: 75vh; overflow: hidden">
          <div
            class="message-item"
            v-for="(item, index) in rowsList"
            :key="index"
            @click="messageInfoStatusChange(item)"
          >
            <div class="message-item-left">
              <img src="../../../assets/portal/notice.png" alt="" />
            </div>

            <div class="message-item-right">
              <div>
                {{ item.title }}
                <span style="float: right; font-size: 14px; color: #bbb">{{
                  item.atime
                }}</span>
              </div>
              <div>
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total"
          style="float: right"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
onMounted(async () => {
  getMessageList();
});
// data
let state = reactive({
  infoContent: '',
  errorMessage: ''
});
// 页面状态
let pagesStatus = reactive({
  messageInfoStatus: false,
  loading: true
});
// 分页数据
let pagination = reactive({
  pageSize: 6,
  pageNo: 1,
  total: 0,
});
// list数据
let rowsList = ref([]);
// methods

// 点击消息
const messageInfoStatusChange = (item) => {
  pagesStatus.messageInfoStatus = true;
  state.infoContent = item.content
};

// 获取所有消息通知
const getMessageList = async () => {
  let res = await get("/examApply/messageList", { pageSize: pagination.pageSize, pageNo: pagination.pageNo });
  if (res.code === 200) {
    pagesStatus.loading = false
    rowsList.value = res.result.list
    pagination.total = res.result.total
    window.localStorage.setItem('messageNum', res.result.total)
    if (res.result.list.length == 0) {
      state.errorMessage = res.message
    } else {
      state.errorMessage = ''
    }
  } else {
    ElMessage.error(res.message)
  }
};
// 分页切换
const handleCurrentChange = (num) => {
  pagination.pageNo = num;
  getMessageList()
};
// 返回
const back = () => {
  pagesStatus.messageInfoStatus = false;
};
</script>
<style lang="scss" scoped>
.score-query {
  height: 89vh;
  overflow: hidden;
  background-color: #ffffff;
}
.message-content {
  overflow-y: auto;
  height: calc(89vh - 0.4167rem);
  padding: 0 60px;
}
.score-query-title {
  text-align: center;
  height: 0.3125rem;
  line-height: 0.3125rem;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 0.0052rem solid #e5e5e5;
  margin-bottom: 0.1042rem;
  .btn {
    position: absolute;
    left: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}
.message-item {
  display: flex;
  justify-content: space-between;
  // height: 0.4896rem;
  height: 10vh;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 0.0208rem;
  margin-bottom: 0.1042rem;
  cursor: pointer;
  .message-item-left {
    width: 10%;
    text-align: center;
    padding-top: 0.1875rem;
    img {
      vertical-align: middle;
    }
  }
  .message-item-right {
    width: 90%;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    padding-right: 0.1042rem;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div:nth-child(1) {
      font-size: 0.0938rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.0521rem;
    }
    div:nth-child(2) {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
}
.message-item:hover {
  background-color: #eee;
}
.errorMessage {
  height: 3.6979rem;
  line-height: 3.6979rem;
  font-size: 24px;
  color: #ccc;
  text-align: center;
}
</style>